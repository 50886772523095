
html body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Inter','Noto Sans Thai';
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--Primary-700);
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

* {
  outline: none;
  box-sizing: border-box;
}
@media print {
  #root > div > div,
  #root > div > div > .main-content,
  #root > div > div > .main-content > .content-block,
  #root > div > div > .main-content > .content-block > div > .wrp-sales > div > div > .body,
  .viewSpecialDoc,
  .subViewSpecialDoc {
    overflow: initial !important;
  }
  #root > div > div > .side-menu,
  #root > div > div > .backgroup_NotificationSide,
  #root > div > div > .main-content > .content-block > div .header,
  #root > div > div > .main-content > .content-block > div header,
  #root > div > div > .main-content > .content-block > div .filter-header,
  #root > div > div > .main-content > .content-block > div > .wrp-sales > .wrp-tab-menu,
  #root > div > div > .main-content > .content-block > div > .wrp-sales > div > .filter-button,
  #root > div > div > .main-content > .content-block > div .wrp-table,
  #root > div > div > .main-content > .content-block > div > .wrp-sales > div > div > .footer,
  #root > div > div > .main-content > .footer,
  #root > div > div > .main-content > .content-block > div .wrp-navigation,
  #root > div > div > .main-content > .content-block > div .wrp-bottom-bar
  {
    display:none !important;
  }
  #root > div > div > .main-content > .content-block > div > .wrp-sales > div > div,
  #root > div > div > .main-content > .content-block > div > div {
    position: initial !important; 
  }
  #root > div > div > .main-content > .content-block > div > .wrp-sales > div > div {
    background-color: initial !important;
  } 
  .printarea > .main-content {
    page-break-after:always;
  }
  #root > div > div > .main-content > .content-block > div > .wrp-sales > div > div > .body > .inner-body {
    width: 100% !important;
  }
  
  @page {
    size: A4;
    margin: 0;
  }
}